<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="tep_postulacion_nacionales_export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar listado Postulación Nacionales
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="fecha_ini" class="col-md-5"
                      >Fecha Inicial</label
                    >
                    <input
                      type="date"
                      id="fecha_ini"
                      v-model="filtros.fecha_ini"
                      class="col-md-7"
                      @change="validaFechas()"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="fecha_fin" class="col-md-5">Fecha Final</label>
                    <input
                      type="date"
                      id="fecha_fin"
                      v-model="filtros.fecha_fin"
                      class="col-md-7"
                      @change="validaFechas()"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="empresa_id" class="col-md-5">Empresa</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="empresa"
                      placeholder="Empresa"
                      label="razon_social"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.empresas"
                      :filterable="true"
                      @input="getSelectEmpresa()"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="solicitud_id"
                              v-model="form.solicitud_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="solicitud_id"
                              ># Solicitud</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_ini1"
                              v-model="form.fecha_ini"
                              class="custom-control-input"                            
                            />
                            <label class="custom-control-label" for="fecha_ini1"
                              >Fecha Inicial</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_fin1"
                              v-model="form.fecha_fin"
                              class="custom-control-input"                              
                            />
                            <label class="custom-control-label" for="fecha_fin1"
                              >Fecha Final</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="empresa_id"
                              v-model="form.empresa_id"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="empresa_id"
                              >Empresa</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_vehiculo_id"
                              v-model="form.tipo_vehiculo_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_vehiculo_id"
                              >Tipo Vehículo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="direccion_origen"
                              v-model="form.direccion_origen"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="direccion_origen"
                              >Origen</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="direccion_destino"
                              v-model="form.direccion_destino"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="direccion_destino"
                              >Destino</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tep_ruta_id"
                              v-model="form.tep_ruta_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tep_ruta_id"
                              >Ruta</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="vehiculo_id"
                              v-model="form.vehiculo_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="vehiculo_id"
                              >Vehículo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="conductor_id"
                              v-model="form.conductor_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="conductor_id"
                              >Conductor</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="estado1"
                              v-model="form.estado"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="estado1"
                              >Estado</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('tep.nacionalesPostulaciones.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "PostulacionNacionalExport",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      empresa: {},
      form: {
        solicitud_id: true,
        fecha_ini: true,
        fecha_fin: true,
        empresa_id: true,
        tipo_vehiculo_id: true,
        direccion_origen: true,
        direccion_destino: true,
        tep_ruta_id: true,
        vehiculo_id: true,
        conductor_id: true,
        estado: true,
      },
      filtros: {
        fecha_ini: null,
        fecha_fin: null,
        empresa_id: null,
        empresa: null,
      },
      listasForms: {
        empresas: [],        
      },
    };
  },

  methods: {
    async getEmpresa() {
      let me = this;
      me.listasForms.empresas = [];
      var url = "api/admin/empresas/lista?estado=1";
      await axios
        .get(url, {
          params: {
            linea_negocio_id: [5],
          },
        })
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getSelectEmpresa() {
      this.filtros.empresa = {};
      this.filtros.empresa_id = null;
      if (this.empresa) {
        this.filtros.empresa = this.empresa;
        this.filtros.empresa_id = this.empresa.id;
      }
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    validaFechas() {
      const fecha_menor = new Date(this.filtros.fecha_ini);
      const fecha_mayor = new Date(this.filtros.fecha_fin);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        this.filtros.fecha_fin = null;
        this.$swal({
          icon: "error",
          title: `La fecha inicial no puede ser mayor a la fecha final...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/PostulacionNacional/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getEmpresa();    
    this.cargando = false;
  },
};
</script>
